import React from 'react'
import Layout from '../components/layout'
import { StickyScrollerEnterpriseAnalytics } from '../components/StickyScrollerEnterpriseAnalytics'
import { WhatWeDoEnterprise, Clients, OurSolutions } from '../components/VerticalComponents'
import { FollowUsModule } from '../components/FollowUsModule'
import heroDesktop from '../../static/images/enterprise-analytics/hero-enterprise.jpg'
import heroMobile from '../../static/images/enterprise-analytics/hero-enterprise-mobile.jpg'

export default class EnterpriseAnalyticsPage extends React.Component {
  render() {
    const { location } = this.props

    return (
      <Layout
        type="home"
        location={location}
        title="Enterprise Analytics"
        description="Data Science & Enterprise Analytics"
      >
        <div className="flex flex-column pa3-ns mw7-m mw8-l center-m center-l ph3">
          <div
            className="mv4-ns h6 dn-s bg-fit"
            style={{
              backgroundImage: `url(${heroDesktop})`,
            }}
          />
          <div
            className="dn-ns bg-fit"
            style={{
              backgroundImage: `url(${heroMobile})`,
              height: 260,
            }}
          />
          <WhatWeDoEnterprise />
          <StickyScrollerEnterpriseAnalytics />
          <Clients logos={logos} />
          <OurSolutions className="pv5 bt b--light-gray" />
        </div>
        <FollowUsModule />
      </Layout>
    )
  }
}

const logos = [
  'blackknight',
  'envelope',
  'generali',
  'ibm',
  'iqvia',
  'rct',
  'unicredit',
  'university-of-california-berkeley',
  'unox',
]
