import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { StickyScroller } from './sticky-scroller/StickyScroller'
import { StickyMobile } from './sticky-scroller/StickyMobile'

const VIDEO_URL = {
  srcDesktop: '/videos/enterprise.mp4',
  poster: '/videos/poster-enterprise.jpg'
}

const getData = () => {
  const data = useStaticQuery(graphql`
    query EnterpriseAnalyticsQuery {
      allEnterpriseAnalyticsJson {
        edges {
          node {
            our_expertise {
              text
              goals
            }
            your_goals {
              imgSrc
              text
              id
            }
            case_study {
              text
              imgSrc
              workPath
            }
          }
        }
      }
    }
  `)
  return data.allEnterpriseAnalyticsJson.edges[0].node
}

export const StickyScrollerEnterpriseAnalytics = () => {
  const { your_goals, our_expertise, case_study } = getData()
  const { srcDesktop, poster } = VIDEO_URL

  return (
    <>
      <StickyMobile
        leftData={your_goals}
        centralData={our_expertise}
        rightData={case_study}
        videoSettings={{ url: srcDesktop, poster }}
      />
      <StickyScroller
        leftData={your_goals}
        centralData={our_expertise}
        rightData={case_study}
        videoSettings={{ url: srcDesktop, poster }}
      />
    </>
  )
}
